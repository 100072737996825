<template>
    <div class="other_page_r">
        <el-card class="card_box">
            <div slot="header" class="card_title">
                <span>消息管理/举报信息</span>
            </div>
            <div>
                <el-form :inline="true" :model="search" class="demo-form-inline">
                    <el-form-item label="内容">
                        <el-input class="custom_input_170" v-model="searchInfo.key" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="类型">
                        <el-select class="custom_input_170" v-model="searchInfo.type" placeholder="">
                            <el-option label="色情低俗" value="1"></el-option>
                            <el-option label="政治敏感" value="2"></el-option>
                            <el-option label="违法犯罪" value="3"></el-option>

                            <el-option label="垃圾广告" value="4"></el-option>
                            <el-option label="造谣传谣、涉嫌欺诈" value="5"></el-option>
                            <el-option label="非原创内容" value="6"></el-option>
                            <el-option label="其他" value="7"></el-option>
                        </el-select>
                    </el-form-item>
                 
                    <el-form-item>
                        <el-button type="primary" @click="searchBtn">查询</el-button>
                        <el-button type="primary" @click="reset">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
         
            <div class="table_box">
                <tablec :tableData="tableData"></tablec>
            </div>
            <div class="flex justify_content_end aligin_items_c table_footer custom_btn">
                <div>

                    <pagination v-show="total > 0" :total="total" :page.sync="searchInfo.page" :limit.sync="searchInfo.size"
                        @pagination="getDataList" />
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import tablec from "./tablec.vue"
    import { getReportList } from "@/api/information";
    import { searchMixin } from "@/utils/searchMixin.js";
    export default {
        mixins: [searchMixin],
        components: {tablec},
        name: '',
        data() {
            return {
                tableData: [],
                total: Number,
                searchInfo: {
                    page: 1,
                    size: 10,
                    type: '',
                    key: ''
                },
              
            }
        },
        created() {
            this.getDataList()
        },
        methods: {
            searchBtn(){
                this.searchInfo.page=1
                this.getDataList()
            },
            getDataList() {
                getReportList(this.searchInfo).then((res) => {
                    if (res.code == 200) {
                        this.tableData = res.data.list
                        this.total = res.data.total;
                    } else {

                        this.tableData = []
                        this.total = 0;
                        
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.$message.error(error.message);
                });
            }
        }
  
    }
</script>

<style scoped>

</style>