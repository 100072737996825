import request from "@/utils/request";

//反馈列表
export function getFeedbackList(obj) {
	return request({
		url: "/admin/notice/getFeedbackList",
		method: "post",
		data: obj,
	});
}
//   回复反馈
export function replyFeedback(obj) {
	return request({
		url: "/admin/notice/replyFeedback",
		method: "post",
		data: obj,
	});
}
//   删除反馈
export function updateFieldStatus(obj) {
	return request({
		url: "/admin/notice/updateFieldStatus",
		method: "post",
		data: obj,
	});
}
//举报列表
export function getReportList(obj) {
	return request({
		url: "/admin/notice/getReportList",
		method: "post",
		data: obj,
	});
}
//举报详情
export function getReportDetails(obj) {
	return request({
		url: "/admin/notice/getReportDetails",
		method: "post",
		data: obj,
	});
}
//举报屏蔽/删除
export function statusReport(obj) {
	return request({
		url: "/admin/notice/statusReport",
		method: "post",
		data: obj,
	});
}
