<template>
    <div class="table">
        <el-table border ref="multipleTable" :data="tableData" :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}" tooltip-effect="dark" style="width: 100%"
            height="60vh"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>

            <el-table-column prop="desc" label="举报内容" >
            </el-table-column>
            <el-table-column  label="举报类型" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.type === 1">色情低俗</span>
                    <span v-if="scope.row.type === 2">政治敏感</span>
                    <span v-if="scope.row.type === 3">违法犯罪</span>
                    <span v-if="scope.row.type === 4">垃圾广告</span>
                    <span v-if="scope.row.type === 5">造谣传谣、涉嫌欺诈</span>
                    <span v-if="scope.row.type === 6">非原创内容</span>
                    <span v-if="scope.row.type === 7">其他</span>
                </template>
             
            </el-table-column>
            <el-table-column prop="users.nickname" label="用户名称" width="120">
            </el-table-column>
         
            <el-table-column prop="create_time" label="时间"  width="200">

            </el-table-column>
         
            <el-table-column fixed="right" label="操作" width="190">
                <template slot-scope="scope">
                    <span class="pointer" @click="toDetail(scope.row)">详情</span>
                    <span class="pointer margin_left_10" @click="toDelete(scope.row)">删除</span>
                </template>
            </el-table-column>
        </el-table>

    </div>
</template>

<script>
      import { statusReport} from "@/api/information";
    export default {
        props: ["tableData"],
        name: '',
        data() {
            return {
                value: true,
               
            }
        },
        methods: {
            toDetail(row) {
                this.$router.push({
                    path: "/reportinfodetail",
                    query: { id: row.id}
                })
            },
            toDelete(row) {
                this.$confirm('此操作将删除该条信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    statusReport({ id: row.id,types:1,status:1 }).then((res) => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.$parent.$parent.getDataList()
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            }
        }
    }
</script>

<style scoped>


</style>